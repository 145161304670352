<template>
  <nav
    class="site-navbar"
    :class="'site-navbar--' + navbarLayoutType"
  >
    <div class="site-navbar__header site-navbar__header__warp">
      <h1
        class="site-navbar__brand site-navbar__brand__warp"
        @click="$router.push({ name: 'home' })"
      >
        <img
          class="site-navbar__brand-lg"
          :src="logo"
          style="height: 90%"
        />
        <span class="site-navbar__brand-mini">哆啦</span>
      </h1>
    </div>
    <div class="nav-bar--warp site-navbar__body clearfix">
      <el-menu
        class="site-navbar__menu"
        mode="horizontal"
      >
        <el-menu-item
          class="site-navbar__switch"
          index="0"
          @click="sidebarFold = !sidebarFold"
        >
          <font-awesome-icon
            icon="fa-solid fa-bars"
            size="lg"
          />
        </el-menu-item>
      </el-menu>
      <div class="nav-bar--title">{{ systemInfo.systemTitle }}</div>
      <div class="nav-bar--right">
        <div
          class="member"
          v-if="systemInfo.systemPath == 'merchant' && authToken.merchantInfoId"
        >
          <div class="item">
            <span class="label">会员状态：</span>
            <span class="value">{{ authToken.status == 1 ? '正常' : '已过期' }}</span>
          </div>
          <div class="item">
            <span class="label">会员到期时间：</span>
            <span class="value">{{ authToken.endTime }}</span>
          </div>
        </div>
        <el-menu
          class="site-navbar__menu site-navbar__menu--right"
          mode="horizontal"
        >
          <el-menu-item
            class="site-navbar__avatar"
            index="3"
          >
            <el-dropdown
              :show-timeout="0"
              placement="bottom"
            >
              <span class="el-dropdown-link">
                {{ userInfo?.username }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- 弹窗, 修改密码 -->
    <x-navbar-update-password
      v-if="updatePassowrdVisible"
      ref="updatePassowrd"
    ></x-navbar-update-password>
  </nav>
</template>

<script>
import Request from '@/commons/request'
import { clearLoginInfo } from '@/utils'
import logoPng from '@/assets/images/logo.png'

export default {
  data() {
    return {
      updatePassowrdVisible: false,
      logo: logoPng
    }
  },
  computed: {
    navbarLayoutType: {
      get() {
        return this.$store.state.common.navbarLayoutType
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold
      },
      set(val) {
        this.$store.commit('common/updateSidebarFold', val)
      }
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs
      },
      set(val) {
        this.$store.commit('common/updateMainTabs', val)
      }
    },
    userInfo: {
      get() {
        return this.$store.state.user.info
      }
    },
    systemInfo: {
      get() {
        return this.$store.getters['system/systemInfo']
      }
    },
    authToken: {
      get() {
        return JSON.parse(sessionStorage.getItem('auth-token') || '{}')
      }
    }
  },
  methods: {
    // 修改密码
    updatePasswordHandle() {
      this.updatePassowrdVisible = true
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init()
      })
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Request.postLogout()
            .catch(() => {
              // ignore
            })
            .finally(() => {
              clearLoginInfo()
              this.$router.push({ name: 'login' })
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.nav-bar--warp {
  display: flex;
  justify-content: start;
  align-items: center;
  .member {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nav-bar--title {
    min-width: 320px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(38, 60, 107);
  }
  .nav-bar--right {
    display: flex;
    justify-content: end;
    width: 100%;
    .member {
      .label {
        display: inline-block;
        width: 100px;
      }
    }
  }
  .el-menu.el-menu--horizontal {
    border-bottom: unset !important;
  }
}
.site-navbar__header__warp {
  // background-color: chocolate;
}
.site-navbar__brand__warp {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.site-navbar__brand-mini {
  color: rgb(38, 60, 107) !important;
}
</style>
