<template>
  <el-submenu
    v-if="menu.list && menu.list.length >= 1"
    :index="menu.menuId + ''"
    :popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'"
  >
    <template slot="title">
      <font-awesome-icon
        v-if="menu.icon != ''"
        :icon="menu.icon"
        size="lg"
        class="site-sidebar__menu-icon"
      ></font-awesome-icon>
      <span>{{ menu.name }}</span>
    </template>
    <x-sidebar-sub-menu
      v-for="item in menu.list"
      :key="item.menuId"
      :menu="item"
      :dynamicMenuRoutes="dynamicMenuRoutes"
    >
    </x-sidebar-sub-menu>
  </el-submenu>
  <el-menu-item
    v-else
    :index="menu.menuId + ''"
    @click="gotoRouteHandle(menu)"
  >
    <font-awesome-icon
      v-if="menu.icon != ''"
      :icon="menu.icon"
      class="site-sidebar__menu-icon"
    ></font-awesome-icon>
    <span>{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
export default {
  name: 'sub-menu',
  props: {
    menu: {
      type: Object,
      required: true
    },
    dynamicMenuRoutes: {
      type: Array,
      required: true
    }
  },
  computed: {
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin
      }
    }
  },
  methods: {
    // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
    gotoRouteHandle(menu) {
      var route = this.dynamicMenuRoutes.filter((item) => item.meta.menuId === menu.menuId)
      if (route.length >= 1 && this.$route.meta.menuId !== route[0].meta.menuId) {
        this.$router.push({ name: route[0].name })
      }
    }
  }
}
</script>
