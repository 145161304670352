import Vue from 'vue'
import VueAxios from 'vue-axios'
import Axios from 'axios'
import AxiosRetry from 'axios-retry'
import Qs from 'qs'
import { DEFAULT_API_TIME_OUT } from '@/commons/const'

const instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: DEFAULT_API_TIME_OUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

AxiosRetry(instance, {
  retries: 1, // 设置自动发送请求次数
  retryDelay: (retryCount) => {
    return retryCount * 100 // 重复请求延迟
  },
  shouldResetTimeout: true, //  重置超时时间
  retryCondition: (error) => {
    return new Promise((resolve) => {
      if (error.config.params) {
        if (error.config.params.ignore_retry) {
          resolve(false)
          return
        }
      }

      if (error.response.status == 401) {
        Vue.store
          .dispatch('auth/refresh')
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
          .finally(() => {})
      } else {
        //true为打开自动发送请求，false为关闭自动发送请求
        if (error.message.includes('timeout')) {
          resolve(true)
          return
        }
        resolve(false)
      }
    })
  }
})

/**
 * 请求拦截
 */
instance.interceptors.request.use(
  (config) => {
    config.paramsSerializer = {
      serialize: (p) => {
        return Qs.stringify(p, {
          arrayFormat: 'repeat'
        })
      }
    }
    return new Promise((resolve) => {
      Vue.store
        .dispatch('auth/load')
        .then((token) => {
          if (token && token.access_token) {
            config.headers['Authorization'] = `Bearer ${token.access_token}` // 请求头带上token
          }
        })
        .catch(() => {
          // ignore
        })
        .finally(() => {
          resolve(config)
        })
    })
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          break
        case 401:
          window.postMessage({
            cmd: 'refresh_failed'
          })
          break
        default:
          window.postMessage({
            cmd: 'error',
            msg: {
              status: error.response.status,
              params: error.response.data
            }
          })
          break
      }
    }
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, instance)
