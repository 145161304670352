import './plugins'
import App from './App.vue'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import cloneDeep from 'lodash/cloneDeep'
import i18n from './plugins/vue-i18n'
import router from './router'
import store from './store'
import './icons'

Vue.use(VueCookie)
Vue.config.productionTip = false

Vue.store = store

window.SITE_CONFIG = window.SITE_CONFIG || {}
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
