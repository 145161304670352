import router from '@/router'
import store from '@/store'
import md5 from 'blueimp-md5'

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16)
  })
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  sessionStorage.removeItem('auth-token')
  sessionStorage.removeItem('system')
  sessionStorage.removeItem('authorities')
  sessionStorage.removeItem('menuList')
  sessionStorage.removeItem('dynamicMenuRoutes')

  store.commit('RESET_STORE')
  router.options.initial = false
}

export function createHashMD5(value) {
  return md5(value)
}

export function menuName(menuId) {
  return `ROUTER_MENU_${menuId}`
}

export function tabName(menuId) {
  return `tbs${menuId}`
}
