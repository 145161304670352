import { localize } from 'vee-validate'

export const LOCALES = [
  { locale: 'en', label: 'English' },
  { locale: 'zh-CN', label: '简体中文' }
]

export const DEFAULT_LOCALE = LOCALES[1]

export function changeLocale($i18n, $vuetify, locale) {
  $i18n.locale = locale
  $vuetify.lang.current = locale
  localize(locale)
  const el = document.getElementsByTagName('html')[0]
  el.lang = locale
  sessionStorage.setItem('locale', locale)
}

export function getValueByLocale(locale, obj, key) {
  if (obj[`${key}_${locale}`]) {
    return obj[`${key}_${locale}`]
  }

  return obj[key]
}
