<template>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
  >
    <template v-if="!loading">
      <x-navbar />
      <x-sidebar />
      <div
        class="site-content__wrapper"
        :style="{ 'min-height': documentClientHeight + 'px' }"
      >
        <x-content />
      </div>
    </template>

    <el-dialog
      title="提示"
      :visible="dialogVisible"
      width="30%"
    >
      <div class="session-timeout--title">
        由于长时间没有操作，系统将在{{ Math.floor(timeLeft / 1000) }}秒后自动登出！
      </div>
      <el-progress
        class="session-timeout--progress"
        :stroke-width="26"
        :percentage="percentLeft"
        :color="customColors"
        :show-text="false"
      ></el-progress>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="onSessionTimeoutLogout">退出系统</el-button>
        <el-button
          type="primary"
          @click="onSessionTimeoutClose"
          >保持登录</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Request from '@/commons/request'
import { clearLoginInfo } from '@/utils'

const SESSION_TIMEOUT = 60 * 30 * 1000
const SESSION_LOGOUT_TIMEOUT = 60 * 1000

export default {
  data() {
    return {
      loading: true,
      mousePositionX: 0,
      mousePositionY: 0,
      sessionTimeoutTimer: 0,
      countdownTimer: 0,
      timeLeft: 0,
      percentLeft: 0,
      dialogVisible: false,
      customColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ]
    }
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight
      },
      set(val) {
        this.$store.commit('common/updateDocumentClientHeight', val)
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold
      }
    },
    userInfo: {
      get() {
        return this.$store.state.user.info
      },
      set(val) {
        this.$store.commit('user/update', val)
      }
    }
  },
  created() {
    this.initial()
    this.addEventCheckSessionTimeout()
    this.getUserInfo()

    this.onCheckSessionTimeout()
  },
  mounted() {
    this.resetDocumentClientHeight()
  },
  destroyed() {
    this.removeCheckSessionTimeout()
    window.removeEventListener('message', this.onReceiverMessage)
  },
  methods: {
    initial() {
      if (typeof window.addEventListener != 'undefined') {
        window.addEventListener('message', this.onReceiverMessage, false)
      } else if (typeof window.attachEvent != 'undefined') {
        //for ie
        window.attachEvent('onmessage', this.onReceiverMessage)
      }
    },
    onReceiverMessage(event) {
      if (event && event.data) {
        const { cmd, msg } = event.data
        if (!cmd) {
          return
        }

        if (cmd === 'refresh_failed') {
          this.$router.push({ name: 'login' })
          return
        }

        if (cmd === 'check_session_timeout') {
          this.onCheckSessionTimeout()
          return
        }

        if (cmd === 'auto_submit_form') {
          this.onAutoSubmitForm(msg)
          return
        }

        if (cmd === 'error' && msg) {
          this.$store.commit('SET_ERROR', msg.params)
          switch (msg?.status) {
            case 404:
              this.$router.push({ name: '404' })
              break
            case 500:
              this.$router.push({ name: '500' })
              break
          }
        }
      }
    },
    onCheckSessionTimeout() {
      if (this.dialogVisible === true) {
        return
      }

      if (this.sessionTimeoutTimer !== 0) {
        clearTimeout(this.sessionTimeoutTimer)
      }

      this.sessionTimeoutTimer = setTimeout(() => {
        // Start dialog timer
        this.startDialogTimer()
      }, SESSION_TIMEOUT)
    },
    startDialogTimer() {
      // Clear session timer
      clearTimeout(this.sessionTimeoutTimer)

      if (this.dialogVisible === false) {
        this.$nextTick(() => {
          this.dialogVisible = true
          this.timeLeft = SESSION_LOGOUT_TIMEOUT
          this.percentLeft = Math.floor((this.timeLeft / SESSION_LOGOUT_TIMEOUT) * 100)
        })
      }

      this.startCountdownTimer()
    },
    startCountdownTimer() {
      if (this.countdownTimer !== 0) {
        clearTimeout(this.countdownTimer)
      }

      this.countdownTimer = setTimeout(() => {
        this.percentLeft = Math.floor((this.timeLeft / SESSION_LOGOUT_TIMEOUT) * 100)
        this.timeLeft -= 1000
        if (this.timeLeft <= 0) {
          this.$nextTick(() => {
            this.onSessionTimeoutLogout()
          })
        } else {
          this.startCountdownTimer()
        }
      }, 1000)
    },
    onSessionTimeoutLogout() {
      Request.postLogout()
        .catch(() => {
          // ignore
        })
        .finally(() => {
          clearLoginInfo()
          this.$router.push({ name: 'login' }).then(() => {
            this.$nextTick(() => {
              this.onSessionTimeoutClose()
            })
          })
        })
    },
    onSessionTimeoutClose() {
      clearTimeout(this.countdownTimer)
      clearTimeout(this.sessionTimeoutTimer)
      this.dialogVisible = false
    },
    // 重置窗口可视高度
    resetDocumentClientHeight() {
      this.documentClientHeight = document.documentElement['clientHeight']
      window.onresize = () => {
        this.documentClientHeight = document.documentElement['clientHeight']
      }
    },
    // 获取当前管理员信息
    getUserInfo() {
      Request.getMe().then(({ data }) => {
        this.loading = false
        this.userInfo = data
      })
    },
    addEventCheckSessionTimeout() {
      document.addEventListener('keyup', this.postCheckSessionTimeout)
      document.addEventListener('mouseup', this.postCheckSessionTimeout)
      document.addEventListener('mousemove', this.postCheckSessionTimeout)
      document.addEventListener('touchend', this.postCheckSessionTimeout)
      document.addEventListener('touchmove', this.postCheckSessionTimeout)
    },
    removeCheckSessionTimeout() {
      document.removeEventListener('keyup', this.postCheckSessionTimeout)
      document.removeEventListener('mouseup', this.postCheckSessionTimeout)
      document.removeEventListener('mousemove', this.postCheckSessionTimeout)
      document.removeEventListener('touchend', this.postCheckSessionTimeout)
      document.removeEventListener('touchmove', this.postCheckSessionTimeout)
    },
    postCheckSessionTimeout(event) {
      if (event.type === 'mousemove') {
        if (event.clientX === this.mousePositionX && event.clientY === this.mousePositionY) {
          return
        }
        this.mousePositionX = event.clientX
        this.mousePositionY = event.clientY
      }
      window.postMessage({
        cmd: 'check_session_timeout'
      })
    },
    onAutoSubmitForm(msg) {
      var myWindow = window.open('', '_self')
      myWindow.document.write(msg)
      let t = setTimeout(() => {
        myWindow.document.createElement('form').submit.call(myWindow.document.getElementById('submitForm'))
        clearTimeout(t)
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.session-timeout--title {
  padding: 12px 12px;
}
.session-timeout--progress {
  transform: scaleX(-1);
}
</style>
