import Vue from 'vue'
import Vuex from 'vuex'
import lowerFirst from 'lodash/lowerFirst'
import camelCase from 'lodash/camelCase'
import cloneDeep from 'lodash/cloneDeep'

Vue.use(Vuex)

const modules = {}
const requireModules = require.context('./modules', true, /\.js$/)
requireModules.keys().forEach((fileName) => {
  const moduleConfig = requireModules(fileName)
  const moduleName = lowerFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
  modules[moduleName] = moduleConfig.default || moduleConfig
})

const defaultState = () => ({
  error: {}
})

export default new Vuex.Store({
  modules: modules,
  state: defaultState,
  mutations: {
    SET_ERROR(state, error) {
      Object.assign(state, { error })
    },
    // 重置vuex本地储存状态
    RESET_STORE(state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    }
  },
  getters: {
    error: (state) => state.error
  },
  actions: {}
})
