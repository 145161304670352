import Vue from 'vue'

const $axios = Vue.axios

const Request = {
  getSystemPath: () => {
    const info = Vue.store.getters['system/systemInfo']
    return info.systemPath
  },
  // 获取会员信息
  getMemberInfo: () => $axios.get('/customer/merchant/member/find/merchantMember'),

  // 获取系统信息
  getHostConfig: () => $axios.get('/platform/host/config/sys'),

  // 发送短信验证码
  smsSendVerifyCode: (params) => $axios.get('/message/sms/send/verify/code', { params: params }),

  // 系统用户登陆
  postLogin: (data = {}) =>
    $axios.post(`/${Request.getSystemPath()}/auth/login`, data, { params: { ignore_retry: true } }),
  // 系统短信登陆
  postSmsLogin: (data = {}) =>
    $axios.post(`/${Request.getSystemPath()}/auth/sms/login`, data, { params: { ignore_retry: true } }),
  // 刷新TOKEN
  postRefreshToken: (data) =>
    $axios.post(`/${Request.getSystemPath()}/auth/refresh`, data, {
      headers: { 'Content-Type': 'text/plain' },
      params: { ignore_retry: true }
    }),
  // 系统用户登出
  postLogout: () => $axios.post(`/${Request.getSystemPath()}/auth/logout`, null, { params: { ignore_retry: true } }),

  // 获取当前系统用户可操作的菜单
  getSysMenuList: () => $axios.get(`/${Request.getSystemPath()}/sys/menu/nav`),
  // 获取当前系统用户信息
  getMe: () => $axios.get(`/${Request.getSystemPath()}/sys/user/info`),
  // 修改用户密码
  postUpdatePassword: (data) => $axios.post(`/${Request.getSystemPath()}/sys/user/password`, data)
}

export default Request
