import Vue from 'vue'
import VueRouter from 'vue-router'
import { clearLoginInfo, createHashMD5 } from '@/utils'
import { isURL, isNotEmptyString } from '@/utils/validate'
import AuthorizedLayout from '@/layouts/AuthorizedLayout.vue'
import * as CONST from '@/commons/const'
import Request from '@/commons/request'

Vue.use(VueRouter)

const _import = require('./import-' + process.env.NODE_ENV)

// Router Push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}

// Router Replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err)
}

// 定义默认路由，初始化时会动态完善路由设置
const rootRoute = {
  path: '/',
  component: AuthorizedLayout,
  name: 'index',
  redirect: { name: 'home' },
  meta: { title: 'Root Path' },
  children: [],
  beforeEnter(to, from, next) {
    Vue.store
      .dispatch('auth/load')
      .then(() => {
        next()
      })
      .catch(() => {
        clearLoginInfo()
        next({ name: 'login' })
      })
  }
}

// 这些路由可以直接进入
const directEntryRoutes = [
  { path: '/404', component: _import('errors/404'), name: '404', meta: { title: '404未找到' } },
  { path: '/500', component: _import('errors/500'), name: '500', meta: { title: '系统错误' } }
]

// 这些需要取得系统配制后的才能进入的路由
const needSysConfigDataRoutes = [
  {
    path: '/login',
    name: 'login',
    component: _import('LoginView')
  },
  {
    path: '/about',
    name: 'about',
    component: _import('AboutView')
  }
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    // mode: 'hash',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    initial: false,
    routes: needSysConfigDataRoutes.concat(directEntryRoutes)
  })

const router = createRouter()

// 添加动态(菜单)路由
// 1. 已经添加 or 全局路由, 直接访问
// 2. 获取菜单列表, 添加并保存本地存储
router.beforeEach((to, from, next) => {
  if (router.options.initial || match(to, directEntryRoutes) === true) {
    next()
    return
  }

  init(to, from, next)
})

function init(to, from, next) {
  getSysConfigData()
    .then(() => Vue.store.dispatch('system/load'))
    .then(() => {
      if (match(to, needSysConfigDataRoutes) === true) {
        next()
        return
      }

      buildRootRoute(to, from, next)
    })
    .catch(() => {})
}

function getSysConfigData() {
  return new Promise((resolve, reject) => {
    if (sessionStorage.getItem('system')) {
      resolve()
      return
    }

    Request.getHostConfig()
      .then(({ data }) => {
        sessionStorage.setItem('system', JSON.stringify(data || '{}'))
        resolve()
      })
      .catch((e) => {
        // console.log(`%c${e} 请求系统基本信息失败，跳转至错误画面！！`, 'color:blue')
        router.push({ name: '500' })
        reject(e)
      })
  })
}

function buildRootRoute(to, from, next) {
  Request.getSysMenuList()
    .then(({ data }) => {
      if (!Array.isArray(data.menuList) || data.menuList == 0) {
        Vue.store.commit('SET_ERROR', { message: '没有分配过权限，不能登录系统。' })
        next({ name: '500' })
        return
      }

      const result = generate(getLocalAddress(), data.menuList)

      // 检查是否设置了Home页，如果没有设置，则将第一页面设置为Home页.
      const index = result.routes.findIndex((item) => item.name === 'home')
      if (index < 0) {
        result.routes[0].name = 'home'
      }

      // 设置Router
      router.matcher = createRouter().matcher
      rootRoute.children = result.routes
      router.addRoute(rootRoute)
      router.addRoute({ path: '*', redirect: { name: '404' } })
      router.options.initial = true

      cache(data.menuList, result.routes, [...new Set(data.authorities)])

      next({ ...to, replace: true })
    })
    .catch((e) => {
      console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
      router.push({ name: 'login' })
    })
}

function match(to, routes = []) {
  var children = []
  for (var i = 0; i < routes.length; i++) {
    if (to.path === routes[i].path || to.name === routes[i].name) {
      return true
    } else if (routes[i].children && routes[i].children.length >= 1) {
      children = children.concat(routes[i].children)
    }
  }
  return children.length >= 1 ? match(to, children) : false
}

/**
 * 缓存菜单列表, 用户权限和路由数据到本地存储
 * @param {*} menus getSysMenuList取回的数据
 * @param {*} routes 动态(菜单)路由
 */
function cache(menus, routes, authorities) {
  authorities = authorities || '[]'
  authorities = authorities.sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))

  sessionStorage.setItem('authorities', JSON.stringify(authorities || '[]'))

  sessionStorage.setItem('menuList', JSON.stringify(menus || '[]'))

  sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(routes || '[]'))
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} baseUrl URL中的http(s)://domain:port
 * @param {*} routes 递归创建的动态(菜单)路由
 * @return 返回所创建的动态(菜单)路由
 */
function generate(loaclAddress, menuList = [], result = { routes: [], authorities: [] }) {
  let list = []

  menuList.forEach((el) => {
    if (isNotEmptyString(el.perms)) {
      const perms = el.perms.split(',')
      perms.forEach((s) => {
        if (isNotEmptyString(s)) {
          result.authorities.push(s.trim())
        }
      })
    }

    if (el.type === CONST.MENU_TYPE_MENU_URL && isNotEmptyString(el.url)) {
      result.routes.push(generateRoute(loaclAddress, el))
    }

    if (el.type !== CONST.MENU_TYPE_BUTTON && el.list && el.list.length > 0) {
      list = list.concat(el.list)
    }
  })

  if (list.length > 0) {
    return generate(loaclAddress, list, result)
  }

  return result
}

function generateRoute(loaclAddress, menu) {
  if (!isURL(menu.url) && !/^\//.test(menu.url)) {
    // 不是URL（以http或https开头的），并且不是以[/]绝对路经来头的地址
    // 在原有的地址上加上 http|https:://<domain>/
    menu.url = loaclAddress + menu.url
  }

  const hash = createHashMD5(menu.url)
  const path = `${menu.menuId}/${hash}`

  return {
    path: path,
    name: menu.homePage === 1 ? 'home' : path.replace('/', '-'),
    meta: {
      menuId: menu.menuId,
      title: menu.name,
      isDynamic: true,
      isTab: menu.tabPage === 1 ? true : false,
      iframeUrl: menu.url
    }
  }
}

function getLocalAddress() {
  const curWwwPath = window.document.location.href
  const result = curWwwPath.match(/^http[s]?:\/\/.*?\//)
  return result[0]
}
export default router
