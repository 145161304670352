<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
body {
  // 谷歌与苹果自带浏览器，隐藏办法如下代码。
  &::-webkit-scrollbar {
    display: none;
  }

  // 火狐浏览器，隐藏办法如下代码。
  & {
    scrollbar-width: unset;
  }

  // IE 与微软浏览器，隐藏办法如下代码。
  & {
    -ms-overflow-style: none;
  }
}
</style>
