<script>
export default {
  methods: {
    xDate(date, format = 'YYYY-MM-DD') {
      if (date) {
        return this.$date(date).format(format);
      }
    },
    xDateTime(date, format = 'YYYY-MM-DD hh:mm:ss') {
      if (date) {
        return this.$date(date).format(format);
      }
    },
  },
};
</script>
