import store from '@/store'
import Vue from 'vue'

const confirm = (info = {}) => {
  return new Promise((resolve, reject) => {
    store.commit('confirm/CONFIRM_SET_VALUE', {
      dialog: true,
      info,
      resolve,
      reject
    })
  })
}

Vue.prototype.$confirm = confirm
