import enExtend from '@/assets/locale/vee-validate/en.json'
import zhCNExtend from '@/assets/locale/vee-validate/zh_CN.json'
import { extend, localize, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import zhCN from 'vee-validate/dist/locale/zh_CN.json'
import { digits, email, max, min, regex, required } from 'vee-validate/dist/rules'
import Vue from 'vue'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

setInteractionMode('eager')

extend('digits', {
  ...digits
})

extend('required', {
  ...required
})

extend('min', {
  ...min
})

extend('max', {
  ...max
})

extend('regex', {
  ...regex
})

extend('email', {
  ...email
})

extend('imageInputUploadRequired', {
  validate: (value) => {
    if (value && value.src === '') {
      return false
    }
    return true
  }
})

const merge = (target, source) => {
  target['code'] = source['code']
  Object.assign(target['messages'], source['messages'])
  target['names'] = source['names']
  target['fields'] = source['fields']
}

merge(en, enExtend)
merge(zhCN, zhCNExtend)

// Installing locales
localize({ [en.code]: en, [zhCN.code]: zhCN })
