import * as jose from 'jose'
import Request from '@/commons/request'

export default {
  namespaced: true,
  state: {
    token: {},
    access: {},
    refresh: {},
    error: {}
  },
  mutations: {
    AUTH_SET_TOKEN(state, token) {
      Object.assign(state, { token })
    },
    AUTH_SET_ACCESS(state, access) {
      Object.assign(state, { access })
    },
    AUTH_SET_REFRESH(state, refresh) {
      Object.assign(state, { refresh })
    },
    AUTH_SET_ERROR(state, error) {
      Object.assign(state, { error })
    }
  },
  getters: {
    token: (state) => state.token,
    access: (state) => state.access,
    refresh: (state) => state.refresh,
    error: (state) => state.error
  },
  actions: {
    load({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (typeof state.access_token !== 'undefined') {
          resolve(state.token)
          return
        }

        const val = sessionStorage.getItem('auth-token')
        if (val == null) {
          reject()
          return
        }
        const token = JSON.parse(val)
        commit('AUTH_SET_TOKEN', token)

        const access = jose.decodeJwt(token.access_token)
        commit('AUTH_SET_ACCESS', access)

        const refresh = jose.decodeJwt(token.refresh_token)
        commit('AUTH_SET_REFRESH', refresh)

        resolve(token)
      })
    },
    update(context, token) {
      sessionStorage.setItem('auth-token', JSON.stringify(token))
      context.commit('AUTH_SET_TOKEN', token)

      const access = jose.decodeJwt(token.access_token)
      context.commit('AUTH_SET_ACCESS', access)

      const refresh = jose.decodeJwt(token.refresh_token)
      context.commit('AUTH_SET_REFRESH', refresh)
      return Promise.resolve()
    },
    refresh({ commit, state }) {
      const { token } = state
      if (!token || !token.refresh_token) {
        return Promise.reject('refresh token not existed!')
      }
      return new Promise((resolve, reject) => {
        return Request.postRefreshToken(token.refresh_token)
          .then(({ data }) => {
            sessionStorage.setItem('auth-token', JSON.stringify(data))
            commit('AUTH_SET_TOKEN', data)

            const access = jose.decodeJwt(data.access_token)
            commit('AUTH_SET_ACCESS', access)

            const refresh = jose.decodeJwt(data.refresh_token)
            commit('AUTH_SET_REFRESH', refresh)

            resolve(token)
          })
          .catch((e) => {
            commit('AUTH_SET_ERROR', e)
            reject(e)
          })
      })
    }
  }
}
