export default {
  namespaced: true,
  state: {
    info: {}
  },
  mutations: {
    update(state, info) {
      Object.assign(state, { info })
    }
  }
}
