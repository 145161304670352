import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { DEFAULT_LOCALE } from '@/utils/locale'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE.locale,
  fallbackLocale: DEFAULT_LOCALE.locale,
  messages: {
    en: require('@/assets/locale/en.json'),
    'zh-CN': require('@/assets/locale/zh-CN.json')
  }
})

export default i18n
