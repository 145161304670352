<script>
export default {
  methods: {
    isGranted(permit) {
      const authorities = JSON.parse(sessionStorage.getItem('authorities'))
      if (!authorities) {
        return false
      }
      return authorities.includes(permit)
    }
  }
}
</script>
