/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 判读非空字符串()
 * @param {*} s
 */
export function isNotEmptyString(s) {
  return typeof s === 'string' && /\S/.test(s)
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
