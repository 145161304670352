export default {
  namespaced: true,
  state: {},
  mutations: {
    SYSTEM_SET_INFO(state, info) {
      Object.assign(state, info)
    }
  },
  getters: {
    systemInfo: (state) => state
  },
  actions: {
    load({ commit }) {
      const systemInfo = sessionStorage.getItem('system')
      commit('SYSTEM_SET_INFO', JSON.parse(systemInfo) || {})
      return systemInfo
    }
  }
}
