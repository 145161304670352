import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const registerComponent = function (requireComponent, fileName, componentPrefix) {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
  Vue.component(`${componentPrefix}${componentName}`, componentConfig.default || componentConfig)
}
// 注册布局组件
const layouts = require.context('@/components/layouts', true, /\.vue$/)
layouts.keys().forEach((fileName) => {
  registerComponent(layouts, fileName, 'X')
})

// 注册共享组件
const components = require.context('@/components', false, /\.vue$/)
components.keys().forEach((fileName) => {
  registerComponent(components, fileName, 'X')
})
