import Vue from 'vue'

const registerMixin = function (requireMixin, fileName) {
  const mixinConfig = requireMixin(fileName)
  Vue.mixin(mixinConfig.default || mixinConfig)
}

const mixins = require.context('@/mixins', true, /\.vue$/)
mixins.keys().forEach((fileName) => {
  registerMixin(mixins, fileName)
})
